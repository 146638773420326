import React, { useState, useEffect } from "react";
// react-bootstrap components
import {
  Form,
  Card,
  Col,
  Button,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Dropdown,
} from "react-bootstrap";
import CustomSelect from "../Shared/CustomSelect";
import { showSweetAlert } from "components/Shared/CustomAlert";
import { useHistory } from "react-router-dom";

function WebOrdersModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1200px");
  }, []);

  const history = useHistory();

  const navigateToManageMovements = (order) => {
    history.push({
      pathname: "/manage-movements", // the path of the new page
      state: { order: order }, // the state you want to pass
    });
  };

  const navigateToOrders = (order) => {
    history.push({
      pathname: "/orders", // the path of the new page
      state: { order: order }, // the state you want to pass
    });
  };

  // Movements table
  const returnMovementsTable = () => {
    const returnStatusName = (statusId) => {
      switch (statusId) {
        case 1:
          return "Open";
        case 2:
          return "Open";
        case 3:
          return "In Transit";
        default:
          return "N/A";
      }
    };

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>BatchName</th>
            <th>From Location</th>
            <th>To Location</th>
            <th>Type Name</th>
            <th>Units</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.itemCardWebOrderInfo.map((order, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigateToManageMovements(order)}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <a href="#id">{order.id}</a>
                </td>
                <td>{order.batchName ?? "N/A"}</td>
                <td>{order.fromLocationName ?? "N/A"}</td>
                <td>{order.toLocationName ?? "N/A"}</td>
                <td>{order.typeName ?? "N/A"}</td>
                <td>{order.units ?? "N/A"}</td>
                <td>{returnStatusName(order.statusId)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  // // orders table
  const returnOrdersTable = () => {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Order</th>
            <th>Total Price</th>
            <th>Sub Total</th>
            <th>Total Tax</th>
            {props.itemCardWebOrderInfoType !== "Sold" && <th>Quantity</th>}
            <th>Fulfillment Status</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {props.itemCardWebOrderInfo.map((order, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigateToOrders(order)}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <a href="#id">{order.orderNumber ?? "N/A"}</a>
                </td>
                <td>{`£${order?.totalPrice?.toFixed(2)}` ?? "N/A"}</td>
                <td>{`£${order?.subtotal?.toFixed(2)}` ?? "N/A"}</td>
                <td>{`£${order?.totalTax?.toFixed(2)}` ?? "N/A"}</td>
                {props.itemCardWebOrderInfoType !== "Sold" && (
                  <td>{order.quantity ?? "N/A"}</td>
                )}
                <td>{order.fulfillmentStatus ?? "N/A"}</td>
                <td>
                  {order.locationId === 0 ? "Web" : order.location ?? "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">{props.itemCardWebOrderInfoType}</Card.Title>
        {/* <Col md="3" style={SearchStyle}>
            <CustomSearchBox
              placeholder="Search"
              value={props.search}
              handleChange={(e) => props.handleSearchChange(e)}
              handleClear={() => props.handleSearchChange("")}
            />
          </Col> */}
      </Card.Header>
      <Card.Body>
        {props.itemCardWebOrderInfoType === "In Transit"
          ? returnMovementsTable()
          : returnOrdersTable()}
      </Card.Body>
    </Card>
  );
}
export default WebOrdersModal;
