import React, {useEffect, useState, useCallback} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AuditLog from "./AuditLog";
import SalesAndInventory from "./SalesAndInventory";
import CustomSelect from "components/Shared/CustomSelect";
import {showSweetAlert} from "components/Shared/CustomAlert";
import CustomAutoComplete from "components/Shared/CustomAutoComplete";
import {useSelector, useDispatch} from "react-redux";

import {DndProvider} from "react-dnd";
import DraggableImage from "./DraggableImage.js";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

// react-bootstrap components
import {
    Form,
    Card,
    Col,
    Button,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Dropdown,
    Spinner,
} from "react-bootstrap";

import {formatCurrency} from "utils/FormatCurrency.js";
import {parseCurrency} from "utils/ParseCurrency.js";
import NoImage from "../../assets/img/NoImage.jpg";

import {
    PRODUCTS_VIEW,
    PRODUCTS_EDIT,
    PRODUCTS_FULL_VIEW,
    PRODUCTS_WEB,
} from "constants/Permissions.js";
import {getPermission} from "utils/Permissions.js";

function ItemCard(props) {
    const user = useSelector((state) => state.user);

    const [isOnEditingDetails, setIsOnEditingDetails] = useState(false);
    const [details, setDetails] = useState(null);
    const [webDetails, setWebDetails] = useState(null);
    const [salesData, setSalesData] = useState([]);
    const [inventoryData, setInventoryData] = useState([]);
    const [auditLogs, setAuditLogs] = useState([]);

    const [page, setPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(20);

    const [brands, setBrands] = useState([]);
    const [locations, setLocations] = useState([]);
    const [genders, setGenders] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colours, setColours] = useState([]);
    const [googleColours, setGoogleColours] = useState([]);
    const [tags, setTags] = useState([]);
    const [productTags, setProductTags] = useState([]);

    const [invSeasons, setInvSeasons] = useState([]);
    const [selectedSeasonId, setSelectedSeasonId] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [isItemCardLoading, setIsItemCardLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const [isUploading, setIsUploading] = useState(false);

    const [selectedImage, setSelectedImage] = useState(NoImage);

    const canEditFull =
        getPermission(user?.permissions, PRODUCTS_EDIT) != null ? true : false;
    const canEditWeb =
        getPermission(user?.permissions, PRODUCTS_WEB) != null || canEditFull
            ? true
            : false;
    const hasFullViewPermission =
        getPermission(user?.permissions, PRODUCTS_FULL_VIEW) != null ? true : false;

    const [value, setValue] = useState("");

    const modules = {
        toolbar: [
            ["bold"], // Toggle bold
            [{list: "bullet"}], // Toggle bullet points
        ],
    };

    const formats = [
        "bold",
        "list", // Only allow bold and bullet points
    ];

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty("--modal-width", "1600px");
        handleTabSelect(props.itemCardSelectedTab);
        fetchData();
    }, []);

    const handleSaveDetails = () => {
        showSweetAlert(
            "warning",
            `Are you sure you want to update the details?`,
            () => {
                handleUpdateDetails();
            }
        );
    };

    const handlePublishProductCheck = () => {
        showSweetAlert(
            "warning",
            `Are you sure you want to publish to Shopify?`,
            () => {
                handlePublishProduct();
            }
        );
    };

    const handlePublishProduct = async () => {
        try {
            setIsSaving(true);
            let response = await fetch(
                `/Product/PublishProduct?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}&productId=${
                    details?.shopifyProductId
                }`,
                {
                    method: "post",
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"],
                    ],
                }
            );

            if (response.status == 200) {
                showSweetAlert("success", "Product has successfully been published");
            } else {
                showSweetAlert("serverError");
            }
            setIsSaving(false);
        } catch (err) {
            showSweetAlert("serverError");
        } finally {
            setIsSaving(false);
            fetchData();
        }
    };

    const handleUnpublishProductCheck = () => {
        showSweetAlert(
            "warning",
            `Are you sure you want to unpublish from Shopify?`,
            () => {
                handleUnpublishProduct();
            }
        );
    };

    const handleUnpublishProduct = async () => {
        try {
            setIsSaving(true);
            let response = await fetch(
                `/Product/UnpublishProduct?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}&productId=${
                    details?.shopifyProductId
                }`,
                {
                    method: "post",
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"],
                    ],
                }
            );

            if (response.status == 200) {
                showSweetAlert("success", "Product has successfully been unpublished");
            } else {
                showSweetAlert("serverError");
            }
            setIsSaving(false);
        } catch (err) {
            showSweetAlert("serverError");
        } finally {
            setIsSaving(false);
            fetchData();
        }
    };

    const handleUpdateDetails = async () => {
        const formatedPriceDetails = {
            ...details,
            cost: parseCurrency(details.cost),
            price: parseCurrency(details.price),
            salePrice: parseCurrency(details.salePrice),
        };

        try {
            setIsSaving(true);

            //if (response.status == 200) {
            let response = await fetch(
                `/ProductTag/Save?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}&id=${
                    details.shopifyProductId
                }`,
                {
                    method: "put",
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"],
                    ],
                    body: JSON.stringify(productTags),
                }
            );

            if (response.status == 200) {
                response = await fetch(
                    `/Product/Update?userId=${localStorage.getItem(
                        "userId"
                    )}&userToken=${localStorage.getItem("userToken")}&id=${details.id}`,
                    {
                        method: "put",
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"],
                        ],
                        body: JSON.stringify(formatedPriceDetails),
                    }
                );

                let message = await response.json();

                response = await fetch(
                    `/Product/UpdateWebDetails?userId=${localStorage.getItem(
                        "userId"
                    )}&userToken=${localStorage.getItem("userToken")}`,
                    {
                        method: "put",
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"],
                        ],
                        body: JSON.stringify(webDetails),
                    }
                );

                if (response.status == 200) {
                    showSweetAlert("saveSuccessCustomMessage", message);
                } else {
                    showSweetAlert("serverError");
                }
            } else {
                showSweetAlert("serverError");
            }
            setIsSaving(false);
        } catch (err) {
            showSweetAlert("serverError");
        } finally {
            setIsSaving(false);
            fetchData();
        }
    };

    const fetchInventorySeasons = async () => {
        if (props.isNavBack) return;
        if (details) {
            let seasonUrl = `/ItemCard/GetSeasonDropdownByProduct?userId=${localStorage.getItem(
                "userId"
            )}&&userToken=${localStorage.getItem("userToken")}&&productId=${
                details.id
            }`;
            const seasonResponse = await fetch(seasonUrl);
            const seasonBody = await seasonResponse.json();
            const seasonData = seasonBody?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setInvSeasons(seasonData);
            const maxId = seasonData?.reduce(
                (max, item) => (item.id > max ? item.id : max),
                0
            );
            setSelectedSeasonId(maxId);
        }
    };

    // #### Fetch Data
    const fetchData = async () => {
        try {
            setIsLoading(true);
            // get Single Item Item Card
            let url = `/ItemCard/GetSingle?productId=${
                props.id
            }&userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const productDetails = await response.json();
            setDetails(productDetails);

            setSelectedImage(
                productDetails?.images && productDetails.images.length > 0
                    ? productDetails.images[0].source
                    : NoImage
            );

            let webDetailsUrl = `/ItemCard/GetWebDetails?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&productId=${props.id}`;
            const webDetailsResponse = await fetch(webDetailsUrl);
            const webDetailsBody = await webDetailsResponse.json();

            let globalDropdownsUrl = `/CategoryValue/GetGlobalDropdowns?userId=${localStorage.getItem(
                "userId"
            )}&&userToken=${localStorage.getItem("userToken")}`;
            const globalDropdownsResponse = await fetch(globalDropdownsUrl);
            const globalDropdownsBody = await globalDropdownsResponse.json();

            const locationData = globalDropdownsBody?.locations?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setLocations(locationData);

            const brandData = globalDropdownsBody?.brands?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setBrands(brandData);

            const genderdata = globalDropdownsBody?.genders?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setGenders(genderdata);

            const seasonData = globalDropdownsBody?.seasons?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setSeasons(seasonData);

            const maxId = seasonData?.reduce(
                (max, item) => (item.id > max ? item.id : max),
                0
            );
            setSelectedSeasonId(maxId);

            const categoryData = globalDropdownsBody?.categories?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setCategories(categoryData);

            const sizesData = globalDropdownsBody?.sizes?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setSizes(sizesData);

            const colourData = globalDropdownsBody?.colours?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setColours(colourData);

            const googleColourData = globalDropdownsBody?.googleColours?.map(
                (item) => {
                    return {id: item.id, value: item.name};
                }
            );
            setGoogleColours(googleColourData);

            setWebDetails({
                ...webDetailsBody,
                metaGoogleColorId:
                    googleColourData.find(
                        (item) => item.value === webDetailsBody?.metaGoogleColor
                    )?.id ?? "",
            });

            const tagsData = globalDropdownsBody?.tags?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setTags(tagsData);

            const statusData = globalDropdownsBody?.statuses?.map((item) => {
                return {id: item.id, value: item.name};
            });
            setStatuses(statusData);

            /// Product Tags
            let productTagsUrl = `/ProductTag/GetAllByProduct?userId=${localStorage.getItem(
                "userId"
            )}&&userToken=${localStorage.getItem("userToken")}&&productId=${
                productDetails.shopifyProductId
            }`;
            const productTagsRes = await fetch(productTagsUrl);
            const productTagsBody = await productTagsRes.json();
            const productTagsData = productTagsBody?.map((item) => {
                return {id: item.id, value: item.value};
            });
            setProductTags(productTagsData);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaste = useCallback(
        async (event) => {
            if (isUploading) return;

            if (isOnEditingDetails) return;

            if (details.images.length == 6) {
                showSweetAlert(
                    "warningOkOnly",
                    `You have reached the limit on images for this product.`
                );
                return;
            }

            setIsUploading(true);

            try {
                const items = (event.clipboardData || event.originalEvent.clipboardData)
                    .items;
                for (let index in items) {
                    const item = items[index];
                    if (item.kind === "file") {
                        const blob = item.getAsFile();
                        if (!blob.type.match(/^image\/(png|jpeg|gif)$/)) return; // skip non-image files
                        const reader = new FileReader();

                        reader.onload = async (event) => {
                            const base64Image = event.target.result;

                            //const formData = new FormData();
                            //const byteCharacters = atob(base64Image.split(',')[1]);
                            //const byteNumbers = new Array(byteCharacters.length);
                            //for (let i = 0; i < byteCharacters.length; i++) {
                            //    byteNumbers[i] = byteCharacters.charCodeAt(i);
                            //}
                            //const byteArray = new Uint8Array(byteNumbers);
                            //const blob = new Blob([byteArray], { type: 'image/png' });

                            //formData.append('image', blob);

                            const response = await fetch(
                                `/ItemCard/AddProductImage?userId=${localStorage.getItem(
                                    "userId"
                                )}&userToken=${localStorage.getItem("userToken")}&productId=${
                                    details.shopifyProductId
                                }`,
                                {
                                    method: "post",
                                    headers: [
                                        ["Content-Type", "application/json"],
                                        ["Content-Type", "text/plain"],
                                    ],
                                    body: JSON.stringify(
                                        base64Image.substring(base64Image.indexOf(",") + 1)
                                    ),
                                }
                            );

                            if (response.status === 200) {
                                showSweetAlert("saveSuccess", `Image`);

                                let url = `/ItemCard/GetSingle?productId=${
                                    props.id
                                }&userId=${localStorage.getItem(
                                    "userId"
                                )}&userToken=${localStorage.getItem("userToken")}`;
                                const response = await fetch(url);
                                const productDetails = await response.json();
                                setDetails(productDetails);
                            }
                        };
                        reader.readAsDataURL(blob);
                    }
                }
            } catch (error) {
            } finally {
                // Reset the flag to false after upload is finished
                setIsUploading(false);
            }
        },
        [isUploading, details]
    );

    const updateProductImageOrder = async (fromIndex, toIndex) => {
        const from = details.images[fromIndex].id;

        try {
            const response = await fetch(
                `/ItemCard/UpdateProductImageOrder?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}&productId=${
                    details.shopifyProductId
                }&imageId=${from}&order=${toIndex}`,
                {
                    method: "put",
                }
            );

            if (response.status === 200) {
                var newArray = [...details.images];
                const [movedItem] = newArray.splice(fromIndex, 1);
                newArray.splice(toIndex, 0, movedItem);

                setDetails({...details, images: newArray});
            }
        } catch (error) {
        }
    };

    const handleDeleteImage = (index) => {
        showSweetAlert(
            "warning",
            `Are you sure you want to delete this image?`,
            () => {
                deletProductImage(index);
            }
        );
    };

    const deletProductImage = async (imageIndex) => {
        try {
            const response = await fetch(
                `/ItemCard/DeleteProductImage?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}&productId=${
                    details.shopifyProductId
                }&imageId=${details.images[imageIndex].id}`,
                {
                    method: "delete",
                }
            );

            if (response.status === 200) {
                const updatedImages = details.images.filter(
                    (_, index) => index !== imageIndex
                );
                setDetails({...details, images: updatedImages});
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        window.addEventListener("paste", handlePaste);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("paste", handlePaste);
        };
    }, [handlePaste]);

    const handleTabSelect = (key) => {
        if (key === "Detail") {
            props.setItemCardSelectedTab("Detail");
            //fetchData();
        } else if (key === "Sales") {
            props.setItemCardSelectedTab("Sales");
            fetchInventorySeasons();
            getSalesAndInventoryData();
        } else if (key === "Audit") {
            props.setItemCardSelectedTab("Audit");
            getAuditLogs();
        }
    };

    const handleSeasonChange = (e) => {
        setSelectedSeasonId(e.value);
    };

    //Get sales and inventory data
    const getSalesAndInventoryData = async () => {
        try {
            let url = `/ItemCard/GetSalesSummary?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&productId=${
                props.id
            }&seasonId=${selectedSeasonId}`;
            setIsLoading(true);
            const response = await fetch(url);
            const body = await response.json();
            setSalesData(body);

            let InventoryUrl = `/ItemCard/GetInventory?userId=${localStorage.getItem(
                "userId"
            )}&&userToken=${localStorage.getItem("userToken")}&&productId=${
                props.id
            }&seasonId=${selectedSeasonId}`;
            const Inventoryresponse = await fetch(InventoryUrl);
            const InventoryDetails = await Inventoryresponse.json();
            setInventoryData(InventoryDetails);
            setIsLoading(false);
        } catch (err) {
        }
    };

    useEffect(() => {
        if (props.itemCardSelectedTab === "Sales") {
            getSalesAndInventoryData();
        }
    }, [selectedSeasonId]);

    const getAuditLogs = async () => {
        try {
            let url = `/ItemCard/GetAuditLogsPaged?skip=${skip}&take=${take}&userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&productId=${props.id}`;
            const response = await fetch(url);
            const body = await response.json();

            setAuditLogs(body);
        } catch (err) {
            //setIsLoading(false);
        }
    };
    const shopifyLink = `${process.env.REACT_APP_SHOPIFY}/products/${details?.shopifyProductId}`;

    return (
        <>
            {alert}
            <Tabs
                defaultActiveKey={props.itemCardSelectedTab}
                transition={false}
                animation={false}
                id="uncontrolled-tab-example"
                className="mb-2"
                onSelect={handleTabSelect}
            >
                <Tab eventKey="Detail" title="Details">
                    {isLoading ? (
                        <div
                            style={{height: 120}}
                            className="d-flex justify-content-center align-items-center text-center"
                        >
                            <div>
                                <Spinner animation="border"/>
                                <p>Loading...</p>
                            </div>
                        </div>
                    ) : (
                        <Col>
                            <Form action="" className="form item-card-tab" method="">
                                <Row>
                                    <Col md="12">
                                        <OverlayTrigger
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            overlay={
                                                <Tooltip id="tooltip-255158527">Download Labels PDF</Tooltip>
                                            }
                                        >
                                            <Button
                                                href="#"
                                                className="btn-link btn-xs float-end"
                                                onClick={(e) =>
                                                    props.handleGetLabelPDF(details?.id)
                                                }
                                                variant="link"
                                            >
                                                <i className="fa fa-tag"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        {(canEditFull || canEditWeb) && (
                                            <OverlayTrigger
                                                hF="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                overlay={
                                                    <Tooltip id="tooltip-255158527">{`Edit`}</Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btn-link btn-xs pull-right text-success float-end"
                                                    variant="danger"
                                                    onClick={() => setIsOnEditingDetails(true)}
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                    </Col>
                                    <Col className="" md="9">
                                        <Row>
                                            <Col className="" md="4">
                                                <Form.Group>
                                                    <label>Product Name</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Name"
                                                        type="text"
                                                        value={details?.name}
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        onChange={(e) =>
                                                            setDetails({...details, name: e.target.value})
                                                        }
                                                        onBlur={(e) =>
                                                            setDetails({...details, name: e.target.value})
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Sku</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Sku"
                                                        type="text"
                                                        value={details?.sku}
                                                        disabled
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Barcode</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Barcode"
                                                        type="number"
                                                        value={details?.supplierBarcode}
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        onChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                supplierBarcode: e.target.value,
                                                            })
                                                        }
                                                        onBlur={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                supplierBarcode: e.target.value,
                                                            })
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Shopify Product Id</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Shopify Product Id"
                                                        type="number"
                                                        value={details?.shopifyProductId}
                                                        disabled
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Brand</label>
                                                    <CustomSelect
                                                        placeholder="Brand"
                                                        value={details?.brandId}
                                                        data={brands}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                brand: e.label,
                                                                brandId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Brand Code</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Brand Code"
                                                        type="text"
                                                        value={details?.brandCode}
                                                        disabled
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Season</label>
                                                    <CustomSelect
                                                        placeholder="Season"
                                                        value={details?.seasonId}
                                                        data={seasons}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                season: e.label,
                                                                seasonId: e.value,
                                                            })
                                                        }
                                                        isDisabled={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Gender</label>
                                                    <CustomSelect
                                                        placeholder="Gender"
                                                        value={details?.genderId}
                                                        data={genders}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                gender: e.label,
                                                                genderId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Product Category</label>
                                                    <CustomSelect
                                                        placeholder="Product Category"
                                                        value={details?.productCategoryId}
                                                        data={categories}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                productCategory: e.label,
                                                                productCategoryId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Status</label>
                                                    <CustomSelect
                                                        placeholder="Status"
                                                        value={details?.statusId}
                                                        data={statuses}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                status: e.label,
                                                                statusId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Colour</label>
                                                    <CustomSelect
                                                        placeholder="Colour"
                                                        value={details?.colourId}
                                                        data={colours}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                status: e.label,
                                                                colourId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Size</label>
                                                    <CustomSelect
                                                        placeholder="Size"
                                                        value={details?.sizeId}
                                                        data={sizes}
                                                        handleChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                status: e.label,
                                                                sizeId: e.value,
                                                            })
                                                        }
                                                        isDisabled={!isOnEditingDetails || !canEditFull}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Cost</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Cost"
                                                        type="text"
                                                        value={
                                                            hasFullViewPermission
                                                                ? formatCurrency(details?.cost)
                                                                : formatCurrency(0)
                                                        }
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        onChange={(e) =>
                                                            setDetails({...details, cost: e.target.value})
                                                        }
                                                        onBlur={(e) =>
                                                            setDetails({...details, cost: e.target.value})
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>RRP</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="RRP"
                                                        type="text"
                                                        value={formatCurrency(details?.price)}
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        onChange={(e) =>
                                                            setDetails({...details, price: e.target.value})
                                                        }
                                                        onBlur={(e) =>
                                                            setDetails({...details, price: e.target.value})
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Sale Price</label>
                                                    <Form.Control
                                                        className=""
                                                        placeholder="Sale Price"
                                                        type="text"
                                                        value={formatCurrency(details?.salePrice)}
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        onChange={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                salePrice: e.target.value,
                                                            })
                                                        }
                                                        onBlur={(e) =>
                                                            setDetails({
                                                                ...details,
                                                                salePrice: e.target.value,
                                                            })
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="8">
                                                <Form.Group>
                                                    <label>Tags</label>
                                                    <CustomAutoComplete
                                                        items={tags}
                                                        disabled={!isOnEditingDetails || !canEditFull}
                                                        selectedItems={productTags}
                                                        label="Add Tags"
                                                        handleAddItem={(item) => {
                                                            const newItem = {
                                                                id: item.value,
                                                                value: item.label,
                                                            };
                                                            setProductTags([...productTags, newItem]);
                                                        }}
                                                        handleRemoveItem={(item) => {
                                                            setProductTags([
                                                                ...productTags.filter((i) => i.id !== item.id),
                                                            ]);
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <h4>
                                            Web Details -{" "}
                                            {details?.published ? (
                                                <span className="text-published">Published</span>
                                            ) : (
                                                <span className="text-unpublished">Unpublished</span>
                                            )}
                                        </h4>
                                        <Row>
                                            <Col className="" md="4">
                                                <Form.Group>
                                                    <label>HTML Body</label>

                                                    <ReactQuill
                                                        className={`quill-edit-${isOnEditingDetails}`}
                                                        value={webDetails?.htmlBody}
                                                        onChange={(content) =>
                                                            setWebDetails({
                                                                ...webDetails,
                                                                htmlBody: content,
                                                            })
                                                        }
                                                        modules={modules}
                                                        formats={formats}
                                                        placeholder="HTML Body"
                                                        readOnly={!isOnEditingDetails || !canEditWeb}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Meta Product Summary</label>
                                                            <Form.Control
                                                                placeholder="MetaProductSummary"
                                                                as="textarea"
                                                                style={{height: "90px"}}
                                                                rows={5}
                                                                value={webDetails?.metaProductSummary}
                                                                disabled={!isOnEditingDetails || !canEditWeb}
                                                                onChange={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductSummary: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductSummary: e.target.value,
                                                                    })
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Meta Gender</label>
                                                            <Form.Control
                                                                placeholder="Gender"
                                                                type="text"
                                                                value={webDetails?.metaGender}
                                                                disabled
                                                                onChange={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaGender: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaGender: e.target.value,
                                                                    })
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Meta Product Subtitle</label>
                                                            <Form.Control
                                                                placeholder="Product Subtitle"
                                                                type="text"
                                                                value={webDetails?.metaProductSubtitle}
                                                                disabled={!isOnEditingDetails || !canEditWeb}
                                                                onChange={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductSubtitle: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductSubtitle: e.target.value,
                                                                    })
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Care And Composition</label>
                                                            <Form.Control
                                                                placeholder="MetaProductCustomDescription"
                                                                as="textarea"
                                                                style={{height: "90px"}}
                                                                rows={5}
                                                                value={webDetails?.metaProductCustomDescription}
                                                                disabled={!isOnEditingDetails || !canEditWeb}
                                                                onChange={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductCustomDescription:
                                                                        e.target.value,
                                                                    })
                                                                }
                                                                onBlur={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaProductCustomDescription:
                                                                        e.target.value,
                                                                    })
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Meta Google Color</label>
                                                            <CustomSelect
                                                                placeholder="Google Colour"
                                                                value={webDetails?.metaGoogleColorId}
                                                                data={googleColours}
                                                                handleChange={(e) =>
                                                                    setWebDetails({
                                                                        ...webDetails,
                                                                        metaGoogleColor: e.label,
                                                                        metaGoogleColorId: e.value,
                                                                    })
                                                                }
                                                                isDisabled={!isOnEditingDetails || !canEditWeb}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Shopify Link</label>
                                                            <p>
                                                                <a
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    href={shopifyLink}
                                                                >
                                                                    {shopifyLink}
                                                                </a>
                                                            </p>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="" md="3">
                                        <Row>
                                            <Col md="12">
                                                <img
                                                    src={selectedImage}
                                                    alt="Main Image"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <DraggableImage
                                            images={details?.images}
                                            updateProductImageOrder={updateProductImageOrder}
                                            handleDeleteImage={handleDeleteImage}
                                            setSelectedImage={setSelectedImage}
                                            showDelete={canEditWeb}
                                        />
                                    </Col>
                                </Row>

                                {isOnEditingDetails && (
                                    <Row>
                                        <Col md="12">
                                            <Button
                                                className="btn-fill float-end"
                                                variant="primary"
                                                onClick={() => handleSaveDetails()}
                                            >
                                                {isSaving ? (
                                                    <Spinner
                                                        animation="border"
                                                        className="saving-spinner"
                                                    />
                                                ) : (
                                                    "Save"
                                                )}
                                            </Button>

                                            <Button
                                                className="btn-fill float-end"
                                                variant="info"
                                                onClick={() => setIsOnEditingDetails(false)}
                                            >
                                                {isSaving ? (
                                                    <Spinner
                                                        animation="border"
                                                        className="saving-spinner"
                                                    />
                                                ) : (
                                                    "Cancel"
                                                )}
                                            </Button>
                                            {details?.published ? (
                                                <Button
                                                    className="btn-fill float-end"
                                                    variant="primary"
                                                    onClick={() => handleUnpublishProductCheck()}
                                                >
                                                    {isSaving ? (
                                                        <Spinner
                                                            animation="border"
                                                            className="saving-spinner"
                                                        />
                                                    ) : (
                                                        "Unpublish"
                                                    )}
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="btn-fill float-end"
                                                    variant="primary"
                                                    onClick={() => handlePublishProductCheck()}
                                                >
                                                    {isSaving ? (
                                                        <Spinner
                                                            animation="border"
                                                            className="saving-spinner"
                                                        />
                                                    ) : (
                                                        "Publish"
                                                    )}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        </Col>
                    )}
                </Tab>
                <Tab eventKey="Sales" title="Sales & Inventory">
                    <SalesAndInventory
                        salesData={salesData}
                        inventoryData={inventoryData}
                        handleShowInventoryModal={props.handleShowInventoryModal}
                        handleHideInventoryModal={props.handleHideInventoryModal}
                        handleShowWebOrdersModal={props.handleShowWebOrdersModal}
                        selectedSeasonId={selectedSeasonId}
                        seasons={invSeasons}
                        handleSeasonChange={handleSeasonChange}
                        hasFullViewPermission={hasFullViewPermission}
                        canEdit={canEditFull}
                        handleGetItemCardWebOrderInfo={props.handleGetItemCardWebOrderInfo}
                        isLoading={isLoading}
                    />
                </Tab>
                {hasFullViewPermission && (
                    <Tab eventKey="Audit" title="Audit">
                        <AuditLog auditLogs={auditLogs}/>
                    </Tab>
                )}
            </Tabs>
        </>
    );
}

export default ItemCard;
